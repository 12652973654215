// Colors and variables
$primary-color: #3C3B54;
$secondary-color: #5A4D8D;
$accent-color: #690077;
$frost-color: rgba(255, 255, 255, 0.2);

// Background image and overlay
.login-container {
  position: relative;
  display: flex;
  flex-direction: column;  // Ensures vertical stacking
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('/image/piclumen-1728478398424.png') no-repeat center center/cover;
  font-family: 'Arial', sans-serif;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

// Top section with logo
.top {
  position: absolute; // Positioning it at the top of the screen
  top: 0;
  left: 0; // Align it to the left edge
  z-index: 2; // Ensure it appears above the overlay
  padding: 20px; // Add padding to ensure it's not right at the edge

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start; // Align logo to the left
    width: 100%;

    .logo {
      height: 100px; // Adjust logo size as needed
    }
  }
}

// Form container with reduced width
.form-containerL {
  position: relative;
  z-index: 2;
  background-color: $frost-color;
  padding: 40px 50px;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  max-width: 300px; // Reduced max width
  transition: box-shadow 0.4s ease;
  backdrop-filter: blur(15px);

  &:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
  }
}

// Title
.title {
  font-family: 'Times New Roman';
  font-size: 38px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 300;
}

// Input fields with equal width
.input-field {
  width: 100%; // Set to 100% to make them equal width
  max-width: 280px; // Further reduced max width
  padding: 12px 15px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7); // White placeholder text with slight opacity
  }

  &:focus {
    border-color: $accent-color;
    box-shadow: 0 0 10px rgba(244, 162, 97, 0.5);
    outline: none;
  }
}

// Password container and toggle button
.password-container {
  position: relative;
  width: 90%; // Set to 100% to align with input fields

  .input-field {
    padding-right: 26px; // Space for the icon
  }
  
  .toggle-password {
    position: absolute;
    top: 50%;
    right: -23px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    transition: color 0.3s ease;

    &:hover {
      color: $accent-color;
    }
  }
}

// Button with reduced width
.login-button {
  width: 100%; // Set to 100% for consistency
  max-width: 280px; // Match the input field max width
  padding: 12px;
  border-radius: 8px;
  background-color: $accent-color;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: lighten($accent-color, 10%);
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(1px);
  }
}

// Footer text
.footer-text {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

// Link
.link {
  color: $accent-color;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: lighten($accent-color, 10%);
  }
}
