body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4; /* Light background */
  }
  
  .home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .home-header {
    font-size: 16px;
    display: flex;
    justify-content: space-between; /* This ensures items are spread to opposite sides */
    align-items: center;
    padding: 10px 25px;
    background-color: #eae8ff;
    color: #690077;
  }
  
  .home-header .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Ensure it spans the full width */
  }
  
  .home-header h1 {
    color: #690077;
    font-family: 'Times New Roman', serif;
    font-size: 26px;
    padding-left: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .logout-button {
  
    background-color: #690077; /* Logout button color */
    color: white;
    border: none;
    padding: 10px 10px; /* Slightly larger padding */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s; /* Added transition effects */
    font-weight: bold; /* Bold text for emphasis */
  }
  
  .logout-button:hover {
    background-color: #f1b0b0; /* Darker shade for hover effect */
    transform: translateY(-2px); /* Slight lift effect on hover */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
  }
  
  .main-content {
    padding: 30px; /* Increased padding for a more spacious feel */
    flex: 1;
    overflow-y: auto; /* Allow scrolling if content overflows */
  }
  
  .content-section,
  .schedule-section,
  .files-section {
    margin-bottom: 40px; /* Increased margin for more gap between sections */
    background: white;
    padding: 25px 50px; /* Increased horizontal padding for content sections */
    border-radius: 12px; /* Increased border radius for a softer look */
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1); /* Deeper shadow for depth */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
  }
  
  .content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjusted min size for better layout */
    gap: 30px; /* Increased gap between grid items */
  }
  
  .content-card {
    background: #e7e7e7; /* Light gray background for cards */
    padding: 15px;
    border-radius: 8px; /* Slightly larger border radius */
    transition: transform 0.2s, box-shadow 0.2s; /* Transition effects for cards */
    border: 1px solid #ccc; /* Border for better visibility */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
    width: 100%; /* Make cards take full width of grid item */
  }
  
  .content-card:hover {
    transform: scale(1.05); /* Slightly enlarge card on hover */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Shadow effect on hover */
  }
  
  .schedule-list,
  .files-list {
    list-style-type: none;
    padding: 0;
  }
  
  .schedule-list li,
  .files-list li {
    background: #f0f0f0; /* Light gray for list items */
    margin: 10px 0 15px; /* Space above and below each item */
    padding: 12px; /* Consistent padding */
    border-radius: 6px; /* Rounded corners */
    transition: background-color 0.2s, transform 0.2s; /* Transition effect for list items */
  }
  
  .schedule-list li:hover,
  .files-list li:hover {
    background: #e0e0e0; /* Darker gray on hover */
    transform: scale(1.02); /* Slight scale effect on hover */
  }
  
  .files-grid {
    display: flex;
    flex-wrap: wrap; /* Allows multiple file cards to wrap */
    gap: 30px; /* Increased space between file cards */
  }
  
  .file-card {
    flex: 1 1 calc(30% - 20px); /* Three cards per row with some spacing */
    background: #e7e7e7; /* Light gray background for file cards */
    padding: 15px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    transition: transform 0.3s; /* Transition effect for hover */
  }
  
  .file-card:hover {
    transform: translateY(-2px); /* Lift effect on hover */
  }
  
  .file-name {
    font-weight: bold; /* Bold file names */
    text-decoration: none; /* Remove underline */
    color: #690077; /* Updated link color */
  }
  
  .file-details {
    display: flex;
    justify-content: space-between;
    margin-top: 5px; /* Space between file name and details */
  }
  
  .see-more {
    margin-top: 15px; /* Space before the See More link */
  }
  
  .see-more a {
    color: #690077; /* Link color for See More */
    font-weight: bold; /* Bold for emphasis */
    text-decoration: underline; /* Underlined for visibility */
  }
  
  .announcement-date {
    font-size: 12px; /* Smaller font for date */
    color: #777; /* Gray color for date */
    margin-top: 10px; /* Space above date */
  }
  .footer {
    padding: 20px;
    text-align: center;
    background-color: #eae8ff;
    color: black;
  }  
  .see-more {
    text-align: center; /* Center the text */
    margin-top: 20px; /* Space above the See More link */
  }
  
  .see-more a {
    color: #690077; /* Link color */
    font-weight: bold; /* Bold for emphasis */
    text-decoration: underline; /* Underlined for visibility */
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    text-align: center;
  }
  
  .popup-content h2 {
    margin-bottom: 15px;
  }
  
  .popup-content p {
    margin-bottom: 10px;
    color: #555;
  }
  
  .popup-content .popup-date {
    font-style: italic;
    color: #888;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Semi-transparent dark background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensures it appears on top of other content
    transition: opacity 0.3s ease;
  }
  
  .popup-content {
    background: #fff; // White background for contrast
    border-radius: 10px; // Rounded corners
    padding: 20px; // Inner spacing
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); // Adds a subtle shadow
    width: 90%;
    max-width: 500px; // Limits the size
    text-align: center; // Centers the content
    animation: slide-in 0.3s ease-out; // Slide-in animation
  }
  
  .popup-content h3 {
    margin-bottom: 15px; // Space below the title
    font-size: 1.5rem; // Larger font size
    color: #333; // Dark text color
  }
  
  .popup-content p {
    margin-bottom: 15px; // Space between paragraphs
    color: #555; // Subtle text color
    line-height: 1.6; // Better readability
  }
  
  .popup-content .file-link {
    display: inline-block;
    margin: 10px 0;
    padding: 10px 15px;
    background-color: #007bff; // Blue button
    color: #fff; // White text
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #0056b3; // Darker blue on hover
    }
  }
  
  .close-popup {
    background-color: #e74c3c; // Red button
    color: #fff;
    border: none;
    margin-left: 15px;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 15px;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #c0392b; // Darker red on hover
    }
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-20%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .files-section {
    margin: 2rem 0;
  
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
  
    .files-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.5rem;
      padding: 0 1rem;
    }
  
    .file-card {
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        color: #333;
      }
  
      p {
        font-size: 0.9rem;
        color: #666;
        margin-bottom: 0.5rem;
      }
  
      .file-date {
        font-style: italic;
        color: #888;
      }
  
      .file-link {
        display: inline-block;
        margin-top: 0.5rem;
        padding: 0.5rem 1rem;
        background-color: #007bff;
        color: #fff;
        text-decoration: none;
        border-radius: 4px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  .download-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  .file-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 20px; /* Space between items */
    padding: 20px;
  }
  
  .file-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .file-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-card h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .file-date {
    color: #666;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    .file-container {
      grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    }
  }
  
  @media (max-width: 480px) {
    .file-container {
      grid-template-columns: 1fr; /* 1 item per row */
    }
  }
  .file-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 20px; /* Space between the cards */
    padding: 20px; /* Optional padding around the grid */
  }
  
  .file-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .file-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-card h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .file-card .file-date {
    color: #666;
    font-size: 14px;
  }
  .file-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 20px; /* Space between the cards */
    padding: 20px; /* Optional padding around the grid */
  }
  
  .file-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .file-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-card h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .file-card .file-date {
    color: #666;
    font-size: 14px;
  }
  